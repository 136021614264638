<template>
    <grid-view 
        title="Заказы"
        filter
        create
        create-title="Создать заказ"
        url-create="/order/create"
        url-update="/order/update/:id"
        store-module="order"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Активность', value: 'active', width: 1, sortable: false },
            { text: 'Создан', value: 'created_at_datetime', width: 1, sortable: false },
            { text: 'Конечник', value: 'customer' },
            { text: 'Название', value: 'name' },
            { text: 'Партнер', value: 'partner.name' },
            { text: 'Комментарий', value: 'comment', width: 96, sortable: false },
            { text: 'Статус', value: 'statusLabel', width: 1, sortable: false },
            { text: '', value: 'edit', width: 32, sortable: false },
            { text: '', value: 'remove', width: 32, sortable: false }
        ]"
        :format="formatItems"
        :check-delete-permission="() => {
            return false;
        }"
    >
        <!-- Filter -->
        <template v-slot:filter="{ filter }">
            <v-row>
                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.active"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },{
                        text: 'Нет',
                        value: false
                      },{
                        text: 'Да',
                        value: true
                      }]"
                      label="Активность"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.id"
                        clearable
                        autocomplete="new-email"
                        label="ID" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-menu 
                        v-model="datepicker.filter.created_at" 
                        :close-on-content-click="false" 
                        transition="scale-transition" 
                        offset-y 
                        max-width="290px" 
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                dense
                                v-model="filter.created_at" 
                                :color="$const.color.primary"
                                clearable
                                label="Дата создания" 
                                persistent-hint 
                                prepend-icon="mdi-calendar" 
                                readonly 
                                v-bind="attrs" 
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker 
                            v-model="filter.created_at" 
                            :color="$const.color.primary"
                            no-title 
                            @input="datepicker.filter.created_at = false" 
                        />
                    </v-menu>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.customer"
                        clearable
                        autocomplete="new-email"
                        label="Конечник" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-text-field 
                        dense
                        v-model="filter.name"
                        clearable
                        autocomplete="new-email"
                        label="Название" 
                        type="text" 
                        placeholder=" "
                        :color="$const.color.primary"
                    ></v-text-field>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.partner_id"
                      :color="$const.color.primary"
                      :items="[{
                            text: 'Все',
                            value: null
                          },
                          ...partners
                      ]"
                      label="Партнер"
                    ></v-select>
                </v-col>

                <v-col lg="6" sm="12">
                    <v-select
                      dense
                      v-model="filter.status"
                      :color="$const.color.primary"
                      :items="[{
                        text: 'Все',
                        value: null
                      },
                      ...$store.state.order.status]"
                      label="Статус"
                    ></v-select>
                </v-col>
            </v-row>
        </template>
        <!-- END Filter -->
    </grid-view>
</template>
<script>
import DataListMixin from '@/mixins/DataListMixin.vue'
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'OrderIndex',
    components: { GridView }, 
    mixins: [DataListMixin],
    data() {
        return {
            partners: [],
            datepicker: {
                filter: {
                    created_at: false
                }
            }
        }
    },
    methods: {
        formatItems(items) {
            return _.map(items, (item) => {
                // Active
                _.set(item, 'active', item.active === true ? 'Да' : 'Нет');

                return item;
            })
        } 
    },
    async created() {
        this.partners = await this.getDataList('partner', {
            label: 'name',
            value: 'id'
        });
    }
}
</script>