var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Заказы","filter":"","create":"","create-title":"Создать заказ","url-create":"/order/create","url-update":"/order/update/:id","store-module":"order","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Создан', value: 'created_at_datetime', width: 1, sortable: false },
        { text: 'Конечник', value: 'customer' },
        { text: 'Название', value: 'name' },
        { text: 'Партнер', value: 'partner.name' },
        { text: 'Комментарий', value: 'comment', width: 96, sortable: false },
        { text: 'Статус', value: 'statusLabel', width: 1, sortable: false },
        { text: '', value: 'edit', width: 32, sortable: false },
        { text: '', value: 'remove', width: 32, sortable: false }
    ],"format":_vm.formatItems,"check-delete-permission":function () {
        return false;
    }},scopedSlots:_vm._u([{key:"filter",fn:function(ref){
    var filter = ref.filter;
return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","color":_vm.$const.color.primary,"clearable":"","label":"Дата создания","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},model:{value:(filter.created_at),callback:function ($$v) {_vm.$set(filter, "created_at", $$v)},expression:"filter.created_at"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.datepicker.filter.created_at),callback:function ($$v) {_vm.$set(_vm.datepicker.filter, "created_at", $$v)},expression:"datepicker.filter.created_at"}},[_c('v-date-picker',{attrs:{"color":_vm.$const.color.primary,"no-title":""},on:{"input":function($event){_vm.datepicker.filter.created_at = false}},model:{value:(filter.created_at),callback:function ($$v) {_vm.$set(filter, "created_at", $$v)},expression:"filter.created_at"}})],1)],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Конечник","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.customer),callback:function ($$v) {_vm.$set(filter, "customer", $$v)},expression:"filter.customer"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","autocomplete":"new-email","label":"Название","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.name),callback:function ($$v) {_vm.$set(filter, "name", $$v)},expression:"filter.name"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                        text: 'Все',
                        value: null
                      } ].concat( _vm.partners
                  ),"label":"Партнер"},model:{value:(filter.partner_id),callback:function ($$v) {_vm.$set(filter, "partner_id", $$v)},expression:"filter.partner_id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  } ].concat( _vm.$store.state.order.status),"label":"Статус"},model:{value:(filter.status),callback:function ($$v) {_vm.$set(filter, "status", $$v)},expression:"filter.status"}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }